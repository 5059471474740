@font-face {
  font-family: prestige-elite-std, monospace;
  font-weight: normal;
  font-style: normal;
  src: url("./Fonts/prestige/PrestigeEliteStd.otf");
}

html {
  font-family: prestige-elite-std, monospace;
}

.loading {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
  resize: both;
  background-color: #b2b2b2;
  background-image: url("/public/UI/logo.png");
  background-size: auto;
  /* was the second value */
  background-position: center center;
  /* keeps the logo centered */
  background-repeat: no-repeat;
  /* applies to the logo */
  height: 100%;
  overflow: hidden;
  z-index: 1;
  resize: both;

}

@media only screen and (max-width: 600px) {
  .loading {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    background-size: cover, contain;
    background-repeat: no-repeat, no-repeat;
    background-image: url("/public/UI/Background-mobile.jpg"), url("/public/UI/logo.png");
    background-position: center center, center center;
    background-attachment: fixed, fixed;
    height: 100%;
    overflow: hidden;
    z-index: 1;
    resize: both;

  }

}

.loading-bar-container {
  width: 200px;
  height: 8px;
  background: rgb(107, 103, 103);
  z-index: 10;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid rgb(3, 3, 3);
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: left;
}

.loading-bar {
  height: 8px;
  background: rgb(0, 0, 0);
  border-radius: 50px;
}